import { BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Mastercard({ size, title }: ComponentProps) {
  return (
    <>
      <BaseSvgStyle
        size={size}
        viewBox="0 0 35 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title ? title : ''}</title>
        <rect
          id="BASE"
          x="0.5"
          y="0.5"
          width="34"
          height="23"
          rx="3.5"
          fill="white"
          stroke="#D9D9D9"
        />
        <path
          id="Left"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 17.1569C16.5584 18.1852 15.0128 18.806 13.3238 18.806C9.55511 18.806 6.5 15.7154 6.5 11.903C6.5 8.09057 9.55511 5 13.3238 5C15.0128 5 16.5584 5.62075 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
          fill="#ED0006"
        />
        <path
          id="Right"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 17.1569C19.2172 15.8908 20.1475 14.0068 20.1475 11.903C20.1475 9.79917 19.2172 7.91517 17.75 6.64903C18.9416 5.62075 20.4872 5 22.1762 5C25.9449 5 29 8.09057 29 11.903C29 15.7154 25.9449 18.806 22.1762 18.806C20.4872 18.806 18.9416 18.1852 17.75 17.1569Z"
          fill="#F9A000"
        />
        <path
          id="Middle"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7491 6.64844C19.2163 7.91457 20.1466 9.79856 20.1466 11.9024C20.1466 14.0062 19.2163 15.8902 17.7491 17.1563C16.2819 15.8902 15.3516 14.0062 15.3516 11.9024C15.3516 9.79856 16.2819 7.91457 17.7491 6.64844Z"
          fill="#FF5E00"
        />
      </BaseSvgStyle>
    </>
  );
}

export default Mastercard;
